.our_servs{
  margin-bottom: 50px;
}

.mrg{
  padding: 90px 0 90px 0;
}

.mainn{
  background-color: #F5F5F5;
}
.row{
  margin-right: 0 !important;
}