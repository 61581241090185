.container-bottom{
  margin-bottom: 40px;
}

.container-yandex{
  margin-bottom: 90px;
  height: 500px;
}


@media screen and (max-width: 1024px) {
  .container-yandex{
    height: 400px;
  }
}