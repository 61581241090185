.footer{
  margin-top: 90px;
  &__inner{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__title{
    margin-bottom: 50px;
  }
  &__tele{
    text-decoration: none;
    color: inherit;
    font-size: 30px;
  }
  &__mail{
    text-decoration: none;
    color: inherit;
    font-size: 30px;
    margin-bottom: 50px;
  }
  &__box{
    display: flex;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  &__social-title{
    text-align: center;
    margin-bottom: 50px;
  }
  &__social-item{
    padding: 8px 12px;
    background: #f5914f;
    width: 60px;
    height: 60px;
    border-radius: 100px;
    svg{
      fill: white;
      width: 35px;
      height: 45px;
    }
  }
}

.footer__copyright{
  padding: 100px;
  background: #000;
  text-align: center;
  color: white;
  &-logo{
    margin: 0 auto;
    height: 120px;
  }
}