

.header{
  position: absolute;
  width: 100%;
  opacity: 0; /* Set initial opacity to 0 */
  transition: opacity 1s; /* Add a transition for opacity */
  &__inner{
    display: grid;
    grid-template-columns: 1fr auto 1fr; /* Левый блок, Центральный блок (логотип), Правый блок */
    align-items: center; /* Центрирование по вертикали */
    justify-items: center;
    height: 80px; /* Укажите высоту вашего header */
  }
  .menu{
    margin-top: 10px;
    &__list{
      text-align: center;
      float: left;
      margin-bottom: 0;
    }
    &__list-item{
      margin: 0;
      padding: 0;
      position: relative;
      display: inline-block;
    }
    &__list-link{
      padding: 15px;
      text-decoration: none;
      color: #ffffff;
      font-size: 18px;
      line-height: 22px;
    }
  }
  .logo{
    width: 150px;
    height: 100px;
  }
  .nav{
    float: right;
    margin-right: 5%;
    &__social-top{
      display: flex;
    }
    &__social-item{
      display: block;
      padding: 4px 8px;
      background: #ffffff;
      width: 35px;
      height: 35px;
      border-radius: 25px;
      margin: 11px;
    }
    &__social-item svg{
      width: 20px;
      height: 22px;
      fill: #4f4a4a;
      transition: 0.2s linear;
    }
  }
}

.top{
  background-image: linear-gradient(
                  to bottom,
                  rgba(107, 107, 107, 0.53) 0.1%,
                  rgba(107, 107, 107, 0.53) 0.1%
  ),
  url(../images/main.jpg);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  height: 100vh;
  &__content {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 250px 0;
  }
  &__title{
    color: white;
  }
  &__text{
    text-align: center;
    color: white;
  }
  &__btn{
    background: #f5914f;
    text-align: center;
    border: none;
    padding: 15px 50px;
    text-decoration: none;
    border-radius: 5px;
    color: white;
    transition: background-color 0.3s ease;
    &:hover {
      background: #e8803e;
    }
  }
}

.display-1{
  text-align: center !important;
}

.arrow {
  position: absolute;
  bottom: 10px;
  text-align: center;
  svg{
    fill: white;
  }
}
.bounce {
  -moz-animation: bounce 2s infinite;
  -webkit-animation: bounce 2s infinite;
  animation: bounce 2s infinite;
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
}

@media (max-width: 1000px) {
  .menu{
    margin-top: 15px !important;
  }
  .header .logo{
    margin-bottom: 50px;
  }

  .header__inner{
    display: flex;
    flex-direction: column;
  }

  .menu__list{
    display: grid;
    grid-template-columns: 1fr auto 1fr;
  }

}

@media (max-width: 350px) {
  .menu__list{
    display: flex;
    flex-direction: column;
    gap:5px;
  }
}

.header.show {
  opacity: 1; /* Change opacity to 1 for the "show" state */
}

/* Add this animation to control the appearance */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.header {
  animation: fadeIn 1s;
}

.top__content{
  animation: fadeIn 1s;
}