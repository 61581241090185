.imghovers {
  transition: transform 0.3s;
  cursor: pointer;
  &:hover {
    transform: scale(1.05);
  }
}



.carousel{
  padding-bottom: 90px;
  padding-top: 90px;
  background-color: #F5F5F5;
  &__box{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 30px 35px;
  }
  &__box-item{
    border-radius: 1.25rem;
    img{
      object-fit: cover;
      width: 100%;
      height: 100%;
      max-height: 1150px;
      border-radius: 20px;
    }
  }
  &__box-item:first-child{
    grid-column: span 2;
    grid-row: span 2;
  }
}

@media (max-width: 540px){
  .carousel{
    &__box{
      grid-template-columns: repeat(1, 1fr);
    }
    &__box-item:first-child{
      grid-column: 1;
      grid-row: 1;
    }
  }
}

@media (max-width: 992px) and (min-width: 541px){
  .carousel{
    &__box{
      grid-gap: 10px;
      grid-template-columns: repeat(2,1fr);
    }
  }
}

